import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Checkbox, FormLabel, TextField } from '@mui/material';
import { InputNumber } from './InputNumber';
import {
  ICartItem,
  IOptionValue,
  IProduct,
} from '../../Interfaces/generalInterfaces';
import { formatPrice } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
export interface FoodItemDetialProp {
  product: IProduct;
  onAddToCart: (cart: ICartItem) => void;
}
export const FoodItemDetail: React.FC<FoodItemDetialProp> = ({
  product,
  onAddToCart,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const [value, setValue] = useState<number>(1);
  const [remark, setRemark] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);

  const productOptions =
    product.product_options &&
    product.product_options.sort((a, b) => {
      return Number(b.options.is_required) - Number(a.options.is_required);
    });

  React.useEffect(() => {
    const areAllRequiredFieldsValid =
      productOptions &&
      productOptions.every((field) => {
        if (field.options.is_required) {
          const selected = selectedOptions[field.option_id] || [];
          return Array.isArray(selected) && selected.length >= 1;
        }
        return true;
      });
    if (areAllRequiredFieldsValid === undefined && value > 0) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(areAllRequiredFieldsValid && value > 0);
    }
  }, [selectedOptions, productOptions, value]);
  const findSelectedOptionsInProductList = (
    selectedOptions,
    productOptions
  ) => {
    return (
      productOptions &&
      productOptions
        .map((product) => {
          const selected = selectedOptions[product.option_id] || [];
          if (selected.length > 0) {
            const matchedValues = product.options.option_values.filter(
              (optionValue) => selected.includes(optionValue.id)
            );

            return {
              option_id: product.option_id,
              name: product.options.name,
              selected_values: matchedValues,
            };
          }
          return null; // No selected values for this option_id
        })
        .filter(Boolean)
    ); // Filter out any nulls (no selected values)
  };
  const calculateTotalPrice = (options) => {
    return options.reduce((total, option) => {
      const selectedPrice = option.selected_values.reduce(
        (subTotal, value) => subTotal + value.price,
        0
      );
      return total + selectedPrice;
    }, 0);
  };
  const handleDecrease = () => {
    if (value <= 0) {
      return;
    } else {
      setValue(value - 1);
    }
  };

  const handleIncrease = () => {
    setValue(value + 1);
  };
  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };
  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };
  const handeAddToCart = () => {
    if (value === 0) return;
    let item: ICartItem = {
      id: uuidv4(),
      product: product,
      value: value,
      remark: remark,
      variants: selectedOptions,
      priceAdjusting: 0,
    };
    if (productOptions) {
      const selectedOptionList = findSelectedOptionsInProductList(
        selectedOptions,
        productOptions
      );
      item = {
        ...item,
        options: selectedOptionList,
        priceAdjusting: calculateTotalPrice(selectedOptionList),
      };
    }
    return onAddToCart(item);
  };
  const handleSingleSelectChange = (optionId, valueId) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [optionId]: [valueId],
    }));
  };
  const handleMultiSelectChange = (optionId, valueId) => {
    setSelectedOptions((prev) => {
      const prevValues = prev[optionId] || [];
      if (prevValues.includes(valueId)) {
        // Remove value if already selected
        return {
          ...prev,
          [optionId]: prevValues.filter((id) => id !== valueId),
        };
      } else {
        // Add value if not selected
        return {
          ...prev,
          [optionId]: [...prevValues, valueId],
        };
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handeAddToCart();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3 bg-slate-50">
        <img
          className="w-full shadow-md shadow-slate-400"
          src={product.image_url || '/images/food-4.png'}
          alt="sample"
        ></img>
        <div className="px-5 py-1">
          <div className="flex justify-between mt-2">
            <h1 className="text-lg font-bold">{product.name}</h1>
            <h2 className="text-lg font-bold">{formatPrice(product.price)}</h2>
          </div>

          <p className="text-xs font-light my-2">{product.description}</p>
        </div>

        <div className="px-5 py-2">
          {productOptions &&
            productOptions.map((option) => {
              const { id, name, is_required, option_values, min_selection } =
                option.options;

              const isMultiSelect = min_selection === 0 || min_selection > 1;

              const displayName = (value: IOptionValue) => {
                return (
                  <div className="flex justify-between">
                    <span> {value.name} </span>
                    <span>
                      {' '}
                      {value.price > 0 ? `   + ${formatPrice(value.price)} ` : ''}
                    </span>
                  </div>
                );
              };

              return (
                <div className="border p-4 mb-2 rounded-md bg-white mtime">
                  <FormControl
                    key={id}
                    style={{ marginBottom: '10px' }}
                    required={is_required}
                    className="w-full"
                  >
                    <FormLabel
                      className="w-full flex justify-between"
                      required={false}
                    >
                      {name}{' '}
                      {is_required && (
                        <span className=" text-sm text-red-700">
                          * Required
                        </span>
                      )}{' '}
                    </FormLabel>

                    {isMultiSelect ? (
                      // Render Checkboxes for Multi-Select
                      <div>
                        {option_values.map((value) => (
                          <FormControlLabel
                            className="w-full"
                            key={value.id}
                            control={
                              <Checkbox
                                checked={(selectedOptions[id] || []).includes(
                                  value.id
                                )}
                                onChange={() =>
                                  handleMultiSelectChange(id, value.id)
                                }
                              />
                            }
                            label={displayName(value)}
                          />
                        ))}
                      </div>
                    ) : (
                      // Render RadioGroup for Single-Select
                      <div className="w-full" key={id}>
                        <RadioGroup
                          aria-labelledby={`option-${id}`}
                          name={`option-${id}`}
                          onChange={(event) =>
                            handleSingleSelectChange(
                              id,
                              parseInt(event.target.value)
                            )
                          }
                        >
                          {option_values.map((value) => (
                            <FormControlLabel
                              className="w-full"
                              key={value.id}
                              value={value.id.toString()}
                              control={<Radio />}
                              label={displayName(value)}
                              
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    )}
                  </FormControl>
                </div>
              );
            })}
        </div>
        <div className="px-5 py-1 bb-8 pb-20">
          <TextField
            className="w-full bg-white"
            id="outlined-basic"
            label={t('Remark')}
            value={remark}
            variant="outlined"
            onChange={(e) => handleRemarkChange(e)}
          />
        </div>
        <div className="fixed bg-white bottom-0 right-0 left-0 z-50 flex items-center justify-between px-5 py-4">
          <InputNumber
            value={value}
            onDecrease={handleDecrease}
            onIncrease={handleIncrease}
            onValueChange={(e) => handleChange(e)}
          />
          <button
            className={`${
              isButtonEnabled ? 'bg-7m-bg-main' : 'bg-slate-300'
            } px-4 py-2 rounded-xl text-white font-bold`}
            type="submit"
            disabled={!isButtonEnabled}
          >
            Add to cart
          </button>
        </div>
      </div>
    </form>
  );
};
