import React, { useState } from 'react';
import {
  EMessageType,
  EScreenMode,
  IBuzz,
  LanguageSetting,
} from '../../Interfaces/generalInterfaces';
import { Link, useNavigate } from 'react-router-dom';
import { FastfoodIcon, MenuIcon3 } from '../../Assets';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
export interface IBuzzContent {
  closeModal: () => void;
  messageTrigger: (message: string, messageType: EMessageType) => void;
  language: LanguageSetting;
  loading?: boolean;
  buzz: Array<IBuzz>;
  setScreenMode: (screenMode: EScreenMode) => void;
}

export const BuzzContent: React.FC<IBuzzContent> = ({
  closeModal,
  messageTrigger,
  language,
  loading,
  buzz,
  setScreenMode,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const customMessage = (messageContent: string) => {
    try {
      messageTrigger(messageContent, EMessageType.BUZZ);
    } catch (error) {
      console.log(error);
    }
    closeModal();
  };

  return (
    <div className="bg-7m-bg-gray-primary px-5 py-2 w-auto">
      <div
        onClick={() => setScreenMode(EScreenMode.MENU)}
        className="flex align-middle items-center space-x-8 bg-white text-black px-4 border border-7m-gray-100 rounded-xl outline outline-4 outline-7m-pink-100 my-5 cursor-pointer focus:bg-7m-pink-100"
      >
        <MenuIcon3 className="w-14" />
        <div className="text-left">
          <h1 className="font-bold text-xl">{t('Order Here')}</h1>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-1 relative">
        {buzz &&
          buzz.map((item, index) => {
            const { icon } = item;
            const label = item[language];

            return (
              <div
                key={index}
                className="relative rounded-xl bg-white text-black border align-middle text-center p-2 cursor-pointer hover:bg-7m-bg-main hover:text-white"
                onClick={() => customMessage(label)}
              >
                <div className="rounded-[50%] p-2 inline-block border-2 border-7m-pink-50 bg-7m-bg-main">
                  <img
                    className="svg-white w-6"
                    src={`/icons/${icon}`}
                    alt={icon}
                  />
                </div>
                <p className="text-xs">{label}</p>
              </div>
            );
          })}
        {loading && (
          <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center  bg-slate-100 bg-opacity-50">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};
