import React, { useEffect, useState } from 'react';

import '../fonts.css';
import { Box, styled, Divider } from '@mui/material';
 

// Components
import { HeaderNav } from './Components/HeaderNav';

// Types
import {
  IMessage,
  EMessageType,
  EScreenMode,
  IKeyMessage,
  IAccount,
} from '../Interfaces/generalInterfaces';
import ChatContent from './Components/ChatContent';
import { SendMessages } from './Components/SendMessage/SendMessages';
 
export interface IChatPage {
  messageList: IMessage[];
  messageReactions: IKeyMessage[];
  messageTrigger: (message: string, messageType: EMessageType) => void;
  restaurantId: string;
  account: IAccount;
  setScreenMode: (screenMode: EScreenMode) => void;
}

export const Chat: React.FC<IChatPage> = ({
  messageList,
  messageReactions,
  messageTrigger,
  restaurantId,
  account,
  setScreenMode,
}) => {
  // const initializePusher = async () => {
  //   const response = await NetworkManager.clientHandshake(
  //     tableUUID,
  //     dynamicCode,
  //     restaurantUUID
  //   );
  //   clientHandshake(response);
  //   if (!restaurantUUID || !tableUUID || !response.payload.channelId) {
  //     return setComponentError('Error in table identifiers');
  //   }
  //   PusherService.resetInstance();
  //   const pusherService = PusherService.getInstance(restaurantUUID, tableUUID);
  //   const pusher = pusherService.getPusher();

  //   localChannel = pusher.subscribe(
  //     response.payload.channelId
  //   ) as PrivateChannel;

  //   localChannel.bind('pusher:subscription_error', (error: any) => {
  //     toast.error('The connection was closed abnormally.', toastConfig(false));

  //     if (response.payload.channelId) {
  //       getPersistentMessages(response.payload.channelId);
  //     } else {
  //       toast.error('Unexpected error getting channel id', toastConfig(false));
  //     }
  //   });

  //   localChannel.bind('pusher:subscription_succeeded', () => {
  //     if (response.payload.channelId) {
  //       getPersistentMessages(response.payload.channelId);
  //     } else {
  //       toast.error('Unexpected error getting channel id', toastConfig(false));
  //     }

  //     toast.dismiss();
  //     //toast.success('Connection established', toastConfig(true));
  //   });

  //   localChannel.bind(EVENT_MESSAGE_SENT, handleMessageSent);
  //   localChannel.bind(EVENT_MESSAGE_REACTION, handleMessageReactions);
  //   localChannel.bind(EVENT_CLEAR_TABLE, handleSessionClear);
  // };

  // const clientHandshake = async (response: any) => {
  //   if (!tableUUID || !dynamicCode || !restaurantUUID) {
  //     return setComponentError('Error in table identifiers ');
  //   }

  //   if (!response.success || !response.payload || !response.payload?.tableId) {
  //     const errorMessage =
  //       response.message || 'Unhandled initializing connection';
  //     return setComponentError(errorMessage);
  //   }
  //   setLanguageSetting(response?.payload.restaurantInfo?.language);
  //   setTable(response.payload);
  //   getPersistentMessages(response.payload.channelId);
  // };

  // const handleMessageSent = useCallback(
  //   (data: IMessage) => {
  //     console.log('chat message sent');
  //     setMessageList((prevState) => [...prevState, data]);
  //   },
  //   [messageList]
  // );

  // const handleMessageReactions = useCallback(
  //   (data: IMessage) => {
  //     setMessageReactions((prevState) => ({ ...prevState, [data.id]: data }));
  //   },
  //   [messageReactions]
  // );
  // const handleSessionClear = useCallback(
  //   (data) => {
  //     setShowAlert(true);
  //     auth.updateSessionClearFlag(dynamicCode, true);
  //   },
  //   [showAlert]
  // );

  // const closeModal = () => {
  //   setBottomModal(false);
  // };

  // const messageTrigger = async (message: string, messageType: EMessageType) => {
  //   closeModal();
  //   const newMessage: IMessage = {
  //     id: uuidv4(),
  //     message: message,
  //     username: clientName,
  //     timestamp: Date.now(),
  //     messageType: messageType,
  //   };
  //   if (languageSetting?.isTranslate) {
  //     const msg = messageType === 'CHAT' ? message : newMessage.message;
  //     const { data } = await NetworkManager.translateText(
  //       msg,
  //       languageSetting.targetLang
  //     );
  //     const originalMessage = newMessage.message;
  //     newMessage.originalMessage = originalMessage;
  //     newMessage.message = data;
  //   }
  //   const send = await NetworkManager.sendMessageToBackend(
  //     tableId,
  //     newMessage,
  //     table.channelId,
  //     EVENT_MESSAGE_SENT
  //   );
  //   localChannel.trigger(EVENT_MESSAGE_SENT, { ...newMessage });
  //   setMessageList([...messageList, newMessage]);
  // };

  // const getPersistentMessages = async (channelId: string) => {
  //   const response = await NetworkManager.getPersistentMessages(channelId);

  //   if (!response.success) {
  //     const errorMessage =
  //       response.message || 'Unhandled error getting persistent messages';
  //     return console.error(errorMessage);
  //   }

  //   const events = response?.payload?.events || [];
  //   const { messages, reactions } = plainPersistentMessages(events);

  //   setMessageList(messages);
  //   setMessageReactions(reactions);
  // };

  const handleStScreenMode = (screenMode: EScreenMode) => {
    setScreenMode(screenMode);
  };

  const ChatWindow = styled(Box)(
    () => `
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
          backgroundImage: url(${Image}),
          backgroundAttachment: 'fixed',
          backgroundColor: 'rgb(254 238 232 / 37%)',
  `
  );
  return (
    <div
      className="flex flex-col h-screen justify-between"
      style={{ fontFamily: 'Heebo' }}
    >
      <>
        <ChatWindow>
          <Box flex={1}>
            <HeaderNav
              setScreenMode={setScreenMode}
              screenMode={EScreenMode.BACK}
              backURL={''}
              title="Message"
            />
            <ChatContent
              account={account}
              messages={messageList}
              messageReactions={messageReactions}
            />
            <SendMessages
              messageTrigger={messageTrigger}
              accountId={restaurantId}
            ></SendMessages>
          </Box>
          <Divider />
        </ChatWindow>
      </>
    </div>
  );
};
