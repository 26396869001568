import React from 'react';
import { ICartItem } from '../../Interfaces/generalInterfaces';
import _ from 'lodash';
import { formatPrice } from '../../utils';
import { useTranslation } from 'react-i18next';
export interface CartViewBottomProp {
  carts: Array<ICartItem>;
  onViewCart?: () => void;
}
export const CartViewBottom: React.FC<CartViewBottomProp> = ({
  carts,
  onViewCart,
  
}) => {
  const count = _.sumBy(carts, 'value');
  const totalPrice = carts.reduce((total, item) => {
    const priceAdjusting = item?.priceAdjusting || 0
    return total + (item.product.price + priceAdjusting) * item.value;
  }, 0);
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className=" sticky bottom-0 px-5 py-3 bg-7m-pink-100">
      <div
        onClick={onViewCart}
        className="bg-7m-bg-main rounded-2xl border border-7m-gray-100 p-4 flex justify-between space-x-4 align-middle items-center text-white"
      >
        <div>
          <p className=" text-sm">
            {t('View your cart')}:{' '}
            <span className=" font-bold">
              {`${count} `} {t(`item${count > 0 ? 's' : ''}`)}
            </span>
          </p>
        </div>
        <div className="">
          <p>{formatPrice(totalPrice)}</p>
        </div>
      </div>
    </div>
  );
};
